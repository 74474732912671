html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;

  /* font-family: "Rubik", sans-serif; */
  font-family: "Roboto", "Varela Round", sans-serif;
  /* background: url(/img/mario-bg.png) no-repeat; */
  background-size: 100%;
  /* background-color: goldenrod; */
  background-position: bottom;

  min-height: 100%;
}

.cardudisBG {
  background-image: linear-gradient(
    109.8deg,
    rgba(250, 111, 152, 1) 5.6%,
    rgba(255, 189, 55, 1) 91.5%
  );
}
.parent {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

.noPadding {
  padding: 0;
  margin: 0;
}
.whiteForm {
  padding: 20px;
  margin-top: 60px;
}

form button,
form h5 {
  margin: 20px 0;
}

input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #ec407a;
  box-shadow: none;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label {
  color: #ec407a !important;
}

/* label focus color */
.input-field-message input[type="text"]:focus + label,
.materialize-textarea:focus:not([readonly]) + label {
  color: #000000 !important;
}

/* label underline focus color */
.input-field-message input[type="text"]:focus,
.input-field-message input[type="tel"]:focus,
.input-field-message input[type="email"]:focus,
.materialize-textarea:focus:not([readonly]) {
  border-bottom: 2px solid #000000 !important;
  box-shadow: 0 1px 0 0 #000000 !important;
}
/* /// */

.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;

  text-align: center;

  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.iconCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.whiteBorder {
  border: 2px solid;
  border-color: white; /* Green */
}

i.icon-blue {
  color: blue;
  font-size: 50px;
}

.fullWidth {
  width: 100%;
}

.floatingRightButton {
  position: absolute;
  bottom: 8px;
  right: 16px;
  top: 50%;
}

.floatingLeftButton {
  position: absolute;
  bottom: 8px;
  left: 16px;
  top: 50%;
}

.floatingLefTopButton {
  position: absolute;
  left: 16px;
  top: 30px;
}

.floatingRightTopButton {
  position: absolute;
  right: 16px;
  top: 8px;
}

.floatingShopingCart {
  position: fixed;
  /* left: 10%; */
  top: 0px;
  z-index: 9900;
}

.a_transparent {
  color: transparent;
  text-decoration: none;
}

.roundedButtons {
  width: 100%;
}

.roundedButtons ul {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: initial;
  text-align: center;
}
.roundedButtons ul li {
  width: 90px;
  display: inline-block;
  list-style-type: none;
  box-sizing: initial;

  margin: 0px 0px 10px 0px;
  cursor: pointer;
  vertical-align: top;
}

.logoImageHidden {
  opacity: 0;
  margin-top: 15px;
  display: block;
}
.logoImageFadeIn {
  /* transition: opacity 5.5s; */
  opacity: 1;
  margin-top: -25px;
  display: block;
  transition: all 2s;
}

/* EDITOR TABS */
.myTabs {
  margin-top: 28px;
  align-items: center;
  justify-content: center;
}

.tab-buttons {
  margin-bottom: 10px;
}

tab_button {
  margin-right: 15px;
  font-size: 20px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 1s;
  border-bottom: 2px solid transparent;
  display: inline-block;
}

.activeTab {
  border-bottom: 2px solid #42b3f4;
  color: black;
}

/* input */

/* input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
} */

/* nav bar */
.myNavBar {
  background-color: transparent;
  background-image: radial-gradient(
    circle farthest-corner at 2.9% 7.7%,
    rgba(164, 14, 176, 1) 0%,
    rgba(254, 101, 101, 1) 90%
  );
  height: 60px;
  /* background-image: linear-gradient(
    67.6deg,
    rgba(225, 242, 254, 1) -2.8%,
    rgba(193, 224, 250, 1) 44.6%,
    rgba(19, 116, 197, 1) 102.4%
  ); */
  /* background-image: linear-gradient(270deg, #ff512f 0%, #f09819 100%); */
}

.mySideNavBar {
  background-color: transparent;
  background-image: radial-gradient(
    circle farthest-corner at 2.9% 7.7%,
    rgba(164, 14, 176, 1) 0%,
    rgba(254, 101, 101, 1) 90%
  );
  /* min-height: 60px; */
  /* background-image: linear-gradient(
    67.6deg,
    rgba(225, 242, 254, 1) -2.8%,
    rgba(193, 224, 250, 1) 44.6%,
    rgba(19, 116, 197, 1) 102.4%
  ); */
  /* background-image: linear-gradient(270deg, #ff512f 0%, #f09819 100%); */
}

.navbar_padding {
  padding-top: 40px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.sticky1 {
  position: fixed;
  top: 0;
  width: 100px;
  z-index: 1000;
}

.modal {
  z-index: 9999;
}
.logo_font {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
}
/* works content */
.works_content {
  background-color: greenyellow;
  /* overflow: hidden; */
}
.single_works_text {
  margin-bottom: 40px;
}
.single_works_text i {
  background: #fff;
  color: #585b60;
  font-size: 30px;
  border: 1px solid #585b60;
  padding: 18px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.single_works_text:hover i {
  color: #ff3366;
  border: 1px solid #ff3366;
}

.center-content {
  text-align: center;
}

/*Message Section*/

#message {
  background-image: radial-gradient(
    circle farthest-corner at -8.9% 51.2%,
    rgba(255, 124, 0, 1) 0%,
    rgba(255, 124, 0, 1) 15.9%,
    rgba(255, 163, 77, 1) 15.9%,
    rgba(255, 163, 77, 1) 24.4%,
    rgba(19, 30, 37, 1) 24.5%,
    rgba(19, 30, 37, 1) 66%
  );

  padding-bottom: 26px;
}
#message .message_content {
  max-width: 560px;
  margin: 0 auto;
}
.message_heading_text {
  margin-bottom: 40px;
}
#message h2 {
  color: #e03577;
  padding-bottom: 24px;
}
#message .form-group {
  padding: 10px;
}
.form-control {
  width: 100%;
  height: 50px;
  padding: 5px;
  font-family: "Varela Round", sans-serif;
}
/* .simpleInput {
  height: 50px;
  padding: 5px;
  font-family: "Varela Round", sans-serif;
} */

/* footer Section */
.socail_bookmark {
  padding: 70px 0px 40px 0px;
  margin-left: -10px;
}
.socail_bookmark a {
  margin-left: 10px;
}
.socail_bookmark a i {
  font-size: 14px;
  color: #d2d3d5;
  padding: 7px;
  border: 1px solid #d2d3d5;
  border-radius: 50%;
  background: #ffffff;
  width: 30px;
  height: 30px;
  transition: 0.5s;
}
.socail_bookmark a:hover i {
  color: #fff;
  border: 1px solid #fff;
  background: #ff3366;
  transform: scale(1.2);
}

.copyright_text:before {
  border-top: 1px solid #d2d3d5;
  width: 20%;
  padding-top: 30px;
  content: "";
  margin: 0 auto;
  display: block;
}
.copyright_text p {
  color: #d2d3d5;
  font-size: 17px;
  padding: 40px;
}
.copyright_text p i {
  padding: 0px 10px;
  color: #ff0000;
}
.copyright_text p a {
  padding: 0px 10px;
  color: green;
}

.my_loading_view {
  background-color: tomato;
  height: 100vh;

  overflow: hidden;
  z-index: 9999;
  position: relative;
}
.theLoaderDiv {
  margin: 0;
  position: absolute;
  top: 40%;
  display: block;
  text-align: center;
}

.centerDivAlert {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;

  margin-top: -200px;
  margin-left: -150px;
  text-align: center;
  background-image: radial-gradient(
    circle farthest-corner at 2.9% 7.7%,
    rgba(164, 14, 176, 1) 0%,
    rgba(254, 101, 101, 1) 90%
  );
  border-radius: 10px;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  /* color: #000000; */
}
.alertTitle {
  margin-top: 30px;
  color: white;
}
.alertSubTitle {
  /* margin-top: 30px; */
  color: white;
}
.alertButton {
  width: 100px;
}

.centerImage {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fullDiv {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9998;
}

.topOfAll {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
}

.fullDivShopingList {
  /* background: rgba(0, 0, 0, 0.7); */
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9998;
  background-color: white;
}

.fullDivNoBg {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
}
.rounded {
  border-radius: 10px;
}

.whiteText {
  color: white;
  font-size: 28px;
}

.topBorder {
  border-top: 1px solid lightgrey;
}

.edit_button_card_container {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 8px;
}
.edit_button_card_container_gray {
  /* background-color: #f4f4f4; */
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 8px;
}

.edit_button_card_title {
  font-family: "Varela Round", sans-serif;
  font-size: 10px;
  color: #ccc;
  text-align: justify;
}

.edit_button_card_subtitle {
  font-family: "Varela Round", sans-serif;
  font-size: 18px;
  text-align: justify;
}

.edit_button_card_subtitle_center {
  font-family: "Varela Round", sans-serif;
  font-size: 18px;
  text-align: center;
}

.edit_button_card_input {
  font-family: "Varela Round", sans-serif;
  height: 40px;
  width: 100%;
  padding: 4px;
  font-size: 16px;
  border: 1px solid;
  border-color: lightgrey; /* Green */

  /* border-radius: 10px; */
}

.createNewButtonText {
  vertical-align: middle;
  line-height: 36px;
  margin-left: 8px;
  color: white;
  height: 36px;
  font-size: 20px;
  font-family: "Varela Round", sans-serif;
}
.createNewButton {
  vertical-align: middle;
  padding: 10px;
  border: 2px solid;
  border-color: white; /* Green */
  border-radius: 10px;
}

.buttonTypeListText {
  font-size: 20px;
  font-family: "Varela Round", sans-serif;
  color: black !important;
}

.dropdown-content {
  border-radius: 10px;
  transform: none;
}

.curdudis_button {
  min-height: 50px;
  max-height: 100px;
  background: #e0356e;
  border: none;
  text-transform: none;
  margin-top: 24px;
  width: 100%;
  border-radius: 10px;
  font-family: "Varela Round", sans-serif;
  font-size: 20px;
  color: white;
}

.curdudis_button:hover {
  background-color: #f79b23;
  color: #fff;
  text-decoration: none;
}

.curdudis_button:focus {
  background-color: #e0356e;
  color: #fff;
  text-decoration: none;
}

.whiteTextVarela {
  color: white;
  font-family: "Varela Round", sans-serif;
  font-size: 20px;
}
/* #message .larg-btn {
  
}
#message .larg-btn:hover {
  background-color: #f79b23;
  color: #fff;
  text-decoration: none;
} */
.colorPicker {
  position: fixed;
  top: 100px;
  right: 100px;
  z-index: 1000;
}

.digitalCardHeader {
  background: linear-gradient(to bottom right, #606c88, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.iphone {
  width: 100%;
  /* /* max-width: 400px; */
  height: 70vh;
  object-fit: scale-down;
  margin-top: 50px;
}

.bg1 {
  background-color: greenyellow;
}

.bg2 {
  background-color: yellow;
}

.bg3 {
  background-color: green;
}
.home {
  /* height: 100vh; */
  background-image: linear-gradient(
    319.6deg,
    rgba(243, 0, 79, 1) 20.5%,
    rgba(255, 236, 68, 1) 110.9%
  );
  min-height: 100vh;
}

.gradColor {
  background: #ff4e50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f9d423,
    #ff4e50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f9d423,
    #ff4e50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.iphone_desc_box {
  height: 20vh;
  background-color: #42b3f4;
}

.iphoneAllBloack {
  position: relative;
  animation-name: iphoneGetIn;
  animation-duration: 2s;
  animation-delay: 0s;
}

@keyframes iphoneGetIn {
  0% {
    left: -800px;
    top: 0px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}
.home_top_description {
  color: white;
}

.fullHieght {
  height: 100vh;
}
.homeSecondSection {
  /* background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
  
  */
  padding: 20px;
  /* background: linear-gradient(to right, #3a1c71, #d76d77, #ffaf7b); */
}

.bottom_border {
  border-bottom: 1px solid red;
}

.font16 {
  font-family: "Varela Round", sans-serif;
  font-size: 16px;
}

.links_row {
  margin-top: 10px;
  margin-bottom: 0px;
}

/* drag and drop */

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  padding: 10px;
  color: gray;
  font-style: italic;
}

.edit_card_top_panel_buttons {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 8px;
}

.edit_card_top_panel_buttons ul li {
  display: inline-block;
  list-style-type: none;
  box-sizing: initial;
  margin: 0px 8px 0px 8px;
  cursor: pointer;
  vertical-align: top;
}

/* update channges button */

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btnUpdateServer {
  height: 40px;
  color: white;

  background: linear-gradient(271deg, blue, #87d9ff);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  -o-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

.btnNewGallery {
  height: 40px;
  color: white;

  background: linear-gradient(271deg, greenyellow, blue);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  -o-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}

@font-face {
  font-family: "Heebo-Regular";
  src: url("./fonts/Heebo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Heebo-Black */

@font-face {
  font-family: "Heebo-Black";
  src: url("./fonts/Heebo-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Heebo-Bold";
  src: url("./fonts/Heebo-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Heebo-ExtraBold";
  src: url("./fonts/Heebo-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Heebo-Light";
  src: url("./fonts/Heebo-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Heebo-Medium";
  src: url("./fonts/Heebo-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Heebo-Thin";
  src: url("./fonts/Heebo-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AmaticaSC-Bold";
  src: url("./fonts/AmaticaSC-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AmaticaSC-Regular";
  src: url("./fonts/AmaticaSC-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ankaclm-bold-webfont HEBREW";
  src: url("./fonts/ankaclm-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cafe";
  src: url("./fonts/Cafe.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Hebrew Hand Wright";
  src: url("./fonts/HandWright.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "makabiyg-webfont";
  src: url("./fonts/makabiyg-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Chocolate ENGLISH";
  src: url("./fonts/Painting_With_Chocolate.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Breathing ENGLISH";
  src: url("./fonts/Breathing.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Durian Dfus HEB";
  src: url("./fonts/Dorian_dfus.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Durian Ktav HEB";
  src: url("./fonts/Dorian_ktav.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.myCollapseBody {
  padding: 0px 0px 0px 0px;
}
.selectList {
  font-size: 8px;
}

/* webkit solution */
::-webkit-input-placeholder {
  text-align: center;
}
/* mozilla solution */
input:-moz-placeholder {
  text-align: center;
}

/* .topImage {
  width: 100%;
  height: auto;
  max-height: 250px;
} */

#container {
  position: relative;
  background-color: aqua;
}

#infoi {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.845);
}

.floatWhatsaap {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.my-float {
  margin-top: 10px;
  margin-left: 2px;
  text-align: center;
}

.box {
  position: relative;
  display: inline-block;
  max-width: 400px;
  width: 90%;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box:hover {
  -webkit-transform: scale(1.15, 1.15);
  z-index: 5;
  transform: scale(1.15, 1.15);
}

.box:hover::after {
  opacity: 1;
}

.priceList_grd {
  background: #c6ffdd; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f7797d,
    #fbd786,
    #c6ffdd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f7797d,
    #fbd786,
    #c6ffdd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.priceGrad1 {
  height: 120px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  background: #c6ffdd; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f7797d,
    #fbd786,
    #c6ffdd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f7797d,
    #fbd786,
    #c6ffdd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.reactPlayer {
  pointer-events: none;
}

/* .vidWrapper {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
} */

#navi {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* // shop */

.centerDivShop {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 300px;

  margin-left: -150px;
  text-align: center;
}

.textLongDots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .simpleBorder {
  width: 1px;
  white-space: nowrap;
  border: 2px solid #ddd;
  padding: 8px;
} */
